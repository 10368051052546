import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;


export const readAllSalesContractCancellation = () => {
    return axios
        .get(API_URL + '/protected/salescontractcancellation')
        .then((response) => {
            return response;
        });
};


export const searchSalesContractCancellation = (request: any) => {
    console.log(request);

    return axios
        .post(API_URL + '/protected/salescontractcancellation/search', request)
        .then((response) => {
            return response;
        });
};

export const generateSalesContractCancellation = (request: any) => {
    console.log(request);

    return axios
        .post(API_URL + '/protected/salescontractcancellationgeneration', request)
        .then((response) => {
            return response;
        });
};

export const downloadSalesContractCancellation = (request: any) => {
    console.log(request);

    return axios
        .post(API_URL + '/protected/salescontractcancellation/download', request,
        {
            responseType: "blob",
        })
        .then((response) => {
            return response;
        });
};


export const readSalesContractCancellationGenerations = () => {
    return axios
        .get(API_URL + '/protected/salescontractcancellationgeneration')
        .then((response) => {
            return response;
        });
};