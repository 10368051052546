import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;



export const readDM24LetterGenerations = () => {
    return axios
        .get(API_URL + '/protected/letter/dm24')
        .then((response) => {
            return response;
        });
};

