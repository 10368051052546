import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import webOfficeBanner from "./asset/web_office_banner.png";
import { login, setAuthToken } from "../../service/AuthService";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import "../../css/SalesContract.css";
import { BlockUI } from "primereact/blockui";
import FileSaver from "file-saver";
import { readSalesContract, searchSalesContract } from "../../service/SalesContractService";
import MessageDialog from "../MessageDialog";
import { InputNumber } from "primereact/inputnumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import StatusCodes from "http-status-codes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { cleanSalesContractSearch, setSearchFields, setSearchResult } from "../../state/salesContractSearchSlice";
import {generationF1ALetters, readF1ALetterGenerations} from "../../service/F1ALetterService";
import { Toast } from "primereact/toast";
import {readDM22LetterGenerations} from "../../service/DM22LetterService";
import { generationDM23aLetters, readDM23aLetterGenerations } from "../../service/DM23aLetterService";
import { confirmDialog } from "primereact/confirmdialog";
import {generationDM23bLetters, readDM23bLetterGenerations} from "../../service/DM23bLetterService";
import {readDM24LetterGenerations} from "../../service/DM24LetterService";




const DM24LetterGeneration: React.FC = () => {

    let navigate = useNavigate();
    setAuthToken();
    const [blockedPage, setBlockedPage] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [letterGenerationList, setLetterGenerationList] = useState([]);
    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);
    const toast: any = useRef(null);

    const showInfo = (data: any) => {
        toast.current.show({ severity: 'info', summary: 'Info Message', detail: data.numberOfLetters + ' db DM23B levél létrehozva', life: 3000 });
    }

    const excelDownloadLinkTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
            style={{ borderRadius: "12px", textDecoration: "none" }}
            href={rowData.excelDownloadLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-file-excel mr-2 text-green-500"></i>
            <span className="font-medium">
                {rowData.excelFileName}
            </span>
        </a>
    }


    const wordDownloadLinkTemplate = (rowData: any) => {
        return <a
            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
            style={{ borderRadius: "12px", textDecoration: "none" }}
            href={rowData.wordDownloadLink}
            target="_blank"
            rel="noreferrer"
        >
            <i className="pi pi-file-word mr-2 text-indigo-500"></i>
            <span className="font-medium">
                {rowData.wordFileName}
            </span>
        </a>
    }

    const refresh = () => {
        setLoading(true);
        readDM24LetterGenerations().then(
           
            (response) => {
                console.log(response.data.letterGenerationList);
                setLetterGenerationList(
                        response.data.letterGenerationList
                    );
                setLoading(false);
                setLoading(false);
            },
            (error) => {
                console.log(error.response.status);

            }
        );
     }


    useEffect(() => {
        refresh();
    }, []);




    return (
        <>
                <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-1 md:p-2 lg:p-3">

                    <Panel
                        className="pt-3 md:pt-4 lg:pt-5"
                        header="DM24 levél létrehozások">
                        <DataTable value={letterGenerationList} responsiveLayout="scroll" loading={loading}>
                            <Column alignHeader="left" field="fromDate" header="Dátumtól" sortable></Column>
                            <Column alignHeader="left" field="toDate" header="Dátumig" sortable></Column>
                            <Column alignHeader="left" field="numberOfLetters" header="Levelek száma"></Column>
                            <Column alignHeader="left" field="excelDownloadLink" header="Excel file letöltése" body={excelDownloadLinkTemplate}></Column>
                            <Column alignHeader="left" field="wordDownloadLink" header="Word file letöltése" body={wordDownloadLinkTemplate}></Column>

                        </DataTable>
                    </Panel>

                </div>
        </>
    );
};

export default DM24LetterGeneration;
