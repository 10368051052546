import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../service/AuthService";
import { StyleClass } from "primereact/styleclass";
import { Badge } from "primereact/badge";
import { Ripple } from "primereact/ripple";

import zepterCl100LogoLightBlue from "../asset/zepter_cl100_logo_light_blue.png";

import Dashboard from "./dashboard/Dashboard";
import SalesContract from "./dashboard/SalesContract";
import SearchSalesContract from "./dashboard/SearchSalesContract";
import F1ALetterGeneration from "./dashboard/F1ALetterGeneration";
import DMLetterGeneration from "./dashboard/DM22LetterGeneration";
import DM22LetterGeneration from "./dashboard/DM22LetterGeneration";
import DM22Statistics from "./dashboard/DM22Statistics";
import WelcomeLetterGeneration from "./dashboard/WelcomeLetterGeneration";
import {  ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import F5LetterGeneration from "./dashboard/F5LetterGeneration";
import V2LetterGeneration from "./dashboard/V2LetterGeneration";
import F1AStatistics from "./dashboard/F1AStatistics";
import V1LetterGeneration from "./dashboard/V1LetterGeneration";
import DM23aLetterGeneration from "./dashboard/DM23aLetterGeneration";
import DM23aStatistics from "./dashboard/DM23aStatistics";
import SalesContractCancellationList from "./dashboard/SalesContractCancellationList";
import NyiLetterGeneration from "./dashboard/NyiLetterGeneration";
import Nyi2LetterGeneration from "./dashboard/Nyi2LetterGeneration";
import V1Statistics from "./dashboard/V1Statistics";
import DM23bStatistics from "./dashboard/DM23bStatistics";
import DM23bLetterGeneration from "./dashboard/DM23bLetterGeneration";
import SalesContractCancellationGeneration from "./dashboard/SalesContractCancellationGeneration";
import SearchOptIn from "./dashboard/SearchOptIn";
import DM24LetterGeneration from "./dashboard/DM24LetterGeneration";
import DM24Statistics from "./dashboard/DM24Statistics";

const Main: React.FC = () => {
    let navigate = useNavigate();
    setAuthToken();


    const btnRefSidebar = useRef(null);
    const btnRefDashboard = useRef(null);
    const btnRefStatistics = useRef(null);
    const btnRefLetters = useRef(null);
    const btnRefDM22 = useRef(null);
    const btnRefDM23a = useRef(null);
    const btnRefDM23b = useRef(null);
    const btnRefDM24 = useRef(null);
    const btnRefF1AStatistics = useRef(null);
    const btnRefV1Statistics = useRef(null);


    const btnRefSalesContract = useRef(null);
    const btnRefSalesContractCancellation = useRef(null);
    const btnRefSalesContractCancellationGeneration = useRef(null);
    const btnRefOptIn = useRef(null);
    const btnF1ALetterGeneration = useRef(null);
    const btnWelcomeLetterGeneration = useRef(null);
    const btnF5LetterGeneration = useRef(null);
    const btnV1LetterGeneration = useRef(null);
    const btnNyiLetterGeneration = useRef(null);
    const btnNyi2LetterGeneration = useRef(null);
    const btnV2LetterGeneration = useRef(null);
    const btnDM22LetterGeneration = useRef(null);
    const btnDM23aLetterGeneration = useRef(null);
    const btnDM23bLetterGeneration = useRef(null);
    const btnDM24LetterGeneration = useRef(null);
    const btnRefTopbar = useRef(null);
    const btnRefProfil = useRef(null);

    const [user, setUser] = useState<any>();
    const [userName, setUserName] = useState<String>("");

    //setUserName(getCurrentUser().userName);

    const clear = () => {
        logout();
        navigate("/login");
        // console.log('Logout is called');
    };

    // const localStorageCl100User = getCurrentUser();
    // console.log(localStorageCl100User.email);

    useEffect(() => {
        // console.log("MessageDialog useEffect is called");
        setUserName(getCurrentUser().userName);

    }, []);

    const confirmLogout = () => {
        confirmDialog({
            message: 'Valóban kijelentkezik?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {clear();},
            reject: () => {}
        });
    }

    return (
        <>
            <div className="min-h-screen flex relative lg:static surface-ground">
            <ConfirmDialog />
                <div
                    id="app-sidebar"
                    className={
                        "surface-section h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none"
                    }
                    style={{ width: "280px" }}
                >
                    <div className="flex flex-column h-full">
                        <div
                            className="flex align-items-center px-5 flex-shrink-0"
                            style={{ height: "60px" }}
                        >
                            <img
                                src={zepterCl100LogoLightBlue}
                                height={40}
                                alt="Zepter Club Live 100 logo"
                            />
                        </div>
                        <div className="overflow-y-auto">
                            <ul className="list-none p-3 m-0">
                                <ul className="list-none p-0 m-0 overflow-hidden">
                                    <li>
                                        <StyleClass
                                            nodeRef={btnRefDashboard}
                                            selector="#app-sidebar"
                                            leaveToClassName="hidden"
                                            // leaveActiveClassName="fadeoutleft"
                                        >
                                            <nav
                                                ref={btnRefDashboard}
                                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                // text-indigo-500
                                                style={{borderRadius: "12px"}}
                                                onClick={() => {
                                                    navigate("dashboard");

                                                }}
                                            >
                                                <i className="pi pi-home mr-2 text-indigo-500"></i>
                                                <span

                                                >
                                                    Dashboard
                                                </span>
                                                <Ripple/>
                                            </nav>
                                        </StyleClass>
                                    </li>


                                    <li>
                                        <StyleClass
                                            nodeRef={btnRefSalesContract}
                                            selector="#app-sidebar"
                                            leaveToClassName="hidden"
                                        >
                                            <nav
                                                ref={btnRefSalesContract}
                                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                style={{borderRadius: "12px"}}
                                                onClick={() => {
                                                    navigate(
                                                        "searchsalescontract"
                                                    );

                                                }}
                                            >
                                                <i className="pi pi-search mr-2 text-pink-500"></i>
                                                <span

                                                >
                                                    Szerződés keresés
                                                </span>

                                                <Ripple/>
                                            </nav>
                                        </StyleClass>
                                    </li>


                                    <li>
                                        <StyleClass
                                            nodeRef={btnRefSalesContractCancellation}
                                            selector="#app-sidebar"
                                            leaveToClassName="hidden"
                                        >
                                            <nav
                                                ref={btnRefSalesContractCancellation}
                                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                style={{borderRadius: "12px"}}
                                                onClick={() => {
                                                    navigate(
                                                        "salescontractcancellation"
                                                    );

                                                }}
                                            >
                                                <i className="pi pi-amazon mr-2 text-pink-500"></i>
                                                <span

                                                >
                                                    Bontások keresése
                                                </span>

                                                <Ripple/>
                                            </nav>
                                        </StyleClass>
                                    </li>


                                    <li>
                                        <StyleClass
                                            nodeRef={btnRefSalesContractCancellationGeneration}
                                            selector="#app-sidebar"
                                            leaveToClassName="hidden"
                                        >
                                            <nav
                                                ref={btnRefSalesContractCancellationGeneration}
                                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                style={{borderRadius: "12px"}}
                                                onClick={() => {
                                                    navigate(
                                                        "salescontractcancellationgeneration"
                                                    );

                                                }}
                                            >
                                                <i className="pi pi-history mr-2 text-pink-500"></i>
                                                <span

                                                >
                                                    Központi bontások
                                                </span>

                                                <Ripple/>
                                            </nav>
                                        </StyleClass>
                                    </li>


                                    <li>
                                        <StyleClass
                                            nodeRef={btnRefOptIn}
                                            selector="#app-sidebar"
                                            leaveToClassName="hidden"
                                        >
                                            <nav
                                                ref={btnRefOptIn}
                                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                style={{borderRadius: "12px"}}
                                                onClick={() => {
                                                    navigate(
                                                        "optin"
                                                    );

                                                }}
                                            >
                                                <i className="pi pi-server mr-2 text-pink-500"></i>
                                                <span

                                                >
                                                    Opt-in keresés
                                                </span>

                                                <Ripple/>
                                            </nav>
                                        </StyleClass>
                                    </li>


                                    <li>
                                        <StyleClass
                                            nodeRef={btnRefLetters}
                                            selector="@next"
                                            enterClassName="hidden"
                                            enterActiveClassName="slidedown"
                                            leaveToClassName="hidden"
                                            leaveActiveClassName="slideup"
                                        >
                                            <nav
                                                ref={btnRefLetters}
                                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                style={{
                                                    borderRadius: "12px",
                                                }}
                                            >
                                                <i className="pi pi-align-justify mr-2 text-blue-500"></i>
                                                <span
                                                >
                                                    Levelek
                                                </span>
                                                <i className="pi pi-chevron-down ml-auto"></i>
                                                <Ripple/>
                                            </nav>
                                        </StyleClass>
                                        <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">

                                            <li>
                                                <StyleClass
                                                    nodeRef={btnF1ALetterGeneration}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnF1ALetterGeneration}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{borderRadius: "12px"}}
                                                        onClick={() => {
                                                            navigate(
                                                                "f1aletter"
                                                            );

                                                        }}
                                                    >
                                                        <i className="pi pi-share-alt mr-2 text-blue-500"></i>
                                                        <span
                                                        >
                                                    F1A
                                                </span>

                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>

                                            <li>
                                                <StyleClass
                                                    nodeRef={btnDM22LetterGeneration}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnDM22LetterGeneration}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{borderRadius: "12px"}}
                                                        onClick={() => {
                                                            navigate(
                                                                "dm22letter"
                                                            );

                                                        }}
                                                    >
                                                        <i className="pi pi-tags mr-2 text-blue-500"></i>
                                                        <span
                                                        >
                                                    DM22
                                                </span>

                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>


                                            <li>
                                                <StyleClass
                                                    nodeRef={btnDM23aLetterGeneration}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnDM23aLetterGeneration}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{borderRadius: "12px"}}
                                                        onClick={() => {
                                                            navigate(
                                                                "dm23aletter"
                                                            );

                                                        }}
                                                    >
                                                        <i className="pi pi-compass mr-2 text-blue-500"></i>
                                                        <span
                                                        >
                                                    DM23A
                                                </span>

                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>

                                            <li>
                                                <StyleClass
                                                    nodeRef={btnDM23bLetterGeneration}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnDM23bLetterGeneration}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{borderRadius: "12px"}}
                                                        onClick={() => {
                                                            navigate(
                                                                "dm23bletter"
                                                            );

                                                        }}
                                                    >
                                                        <i className="pi pi-compass mr-2 text-blue-500"></i>
                                                        <span
                                                        >
                                                    DM23B
                                                </span>

                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>

                                            <li>
                                                <StyleClass
                                                    nodeRef={btnDM24LetterGeneration}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnDM24LetterGeneration}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{borderRadius: "12px"}}
                                                        onClick={() => {
                                                            navigate(
                                                                "dm24letter"
                                                            );

                                                        }}
                                                    >
                                                        <i className="pi pi-compass mr-2 text-blue-500"></i>
                                                        <span
                                                        >
                                                    DM24
                                                </span>

                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>


                                            <li>
                                                <StyleClass
                                                    nodeRef={btnWelcomeLetterGeneration}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnWelcomeLetterGeneration}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{borderRadius: "12px"}}
                                                        onClick={() => {
                                                            navigate(
                                                                "welcomeletter"
                                                            );

                                                        }}
                                                    >
                                                        <i className="pi pi-map-marker mr-2 text-blue-500"></i>
                                                        <span
                                                        >
                                                    NYV
                                                </span>

                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>


                                            <li>
                                                <StyleClass
                                                    nodeRef={btnF5LetterGeneration}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnF5LetterGeneration}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{borderRadius: "12px"}}
                                                        onClick={() => {
                                                            navigate(
                                                                "f5letter"
                                                            );

                                                        }}
                                                    >
                                                        <i className="pi pi-cloud mr-2 text-blue-500"></i>
                                                        <span
                                                        >
                                                    F5
                                                </span>

                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>

                                            <li>
                                                <StyleClass
                                                    nodeRef={btnV2LetterGeneration}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnV2LetterGeneration}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{borderRadius: "12px"}}
                                                        onClick={() => {
                                                            navigate(
                                                                "v1letter"
                                                            );

                                                        }}
                                                    >
                                                        <i className="pi pi-credit-card mr-2 text-blue-500"></i>
                                                        <span
                                                        >
                                                    V1
                                                </span>

                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>


                                            <li>
                                                <StyleClass
                                                    nodeRef={btnV1LetterGeneration}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnV1LetterGeneration}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{borderRadius: "12px"}}
                                                        onClick={() => {
                                                            navigate(
                                                                "v2letter"
                                                            );

                                                        }}
                                                    >
                                                        <i className="pi pi-image mr-2 text-blue-500"></i>
                                                        <span
                                                        >
                                                    V2
                                                </span>

                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>


                                            <li>
                                                <StyleClass
                                                    nodeRef={btnNyiLetterGeneration}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnNyiLetterGeneration}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{borderRadius: "12px"}}
                                                        onClick={() => {
                                                            navigate(
                                                                "nyiletter"
                                                            );

                                                        }}
                                                    >
                                                        <i className="pi pi-globe mr-2 text-blue-500"></i>
                                                        <span
                                                        >
                                                    NYI
                                                </span>

                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>


                                            <li>
                                                <StyleClass
                                                    nodeRef={btnNyi2LetterGeneration}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnNyi2LetterGeneration}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{borderRadius: "12px"}}
                                                        onClick={() => {
                                                            navigate(
                                                                "nyi2letter"
                                                            );

                                                        }}
                                                    >
                                                        <i className="pi pi-truck mr-2 text-blue-500"></i>
                                                        <span
                                                        >
                                                    NYI2
                                                </span>

                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>


                                        </ul>
                                    </li>


                                    <li>
                                        <StyleClass
                                            nodeRef={btnRefStatistics}
                                            selector="@next"
                                            enterClassName="hidden"
                                            enterActiveClassName="slidedown"
                                            leaveToClassName="hidden"
                                            leaveActiveClassName="slideup"
                                        >
                                            <nav
                                                ref={btnRefStatistics}
                                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                style={{
                                                    borderRadius: "12px",
                                                }}
                                            >
                                                <i className="pi pi-th-large mr-2 text-orange-500"></i>
                                                <span

                                                >
                                                    Statisztika
                                                </span>
                                                <i className="pi pi-chevron-down ml-auto"></i>
                                                <Ripple/>
                                            </nav>
                                        </StyleClass>
                                        <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                            <li>
                                                <StyleClass
                                                    nodeRef={btnRefDM22}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnRefDM22}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{
                                                            borderRadius: "12px",
                                                        }}
                                                        onClick={() => {
                                                            navigate(
                                                                "dm22statistics"
                                                            );
                                                        }}
                                                    >
                                                        <i className="pi pi-money-bill mr-2 text-orange-500"></i>
                                                        <span

                                                        >
                                                            DM22
                                                        </span>
                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>


                                            <li>
                                                <StyleClass
                                                    nodeRef={btnRefDM23a}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnRefDM23a}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{
                                                            borderRadius: "12px",
                                                        }}
                                                        onClick={() => {
                                                            navigate(
                                                                "dm23astatistics"
                                                            );
                                                        }}
                                                    >
                                                        <i className="pi pi-compass mr-2 text-orange-500"></i>
                                                        <span

                                                        >
                                                            DM23A
                                                        </span>
                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>

                                            <li>
                                                <StyleClass
                                                    nodeRef={btnRefDM23b}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnRefDM23b}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{
                                                            borderRadius: "12px",
                                                        }}
                                                        onClick={() => {
                                                            navigate(
                                                                "dm23bstatistics"
                                                            );
                                                        }}
                                                    >
                                                        <i className="pi pi-compass mr-2 text-orange-500"></i>
                                                        <span

                                                        >
                                                            DM23B
                                                        </span>
                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>

                                            <li>
                                                <StyleClass
                                                    nodeRef={btnRefDM24}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnRefDM24}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{
                                                            borderRadius: "12px",
                                                        }}
                                                        onClick={() => {
                                                            navigate(
                                                                "dm24statistics"
                                                            );
                                                        }}
                                                    >
                                                        <i className="pi pi-compass mr-2 text-orange-500"></i>
                                                        <span

                                                        >
                                                            DM24
                                                        </span>
                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>


                                            <li>
                                                <StyleClass
                                                    nodeRef={btnRefF1AStatistics}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnRefF1AStatistics}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{
                                                            borderRadius: "12px",
                                                        }}
                                                        onClick={() => {
                                                            navigate(
                                                                "f1astatistics"
                                                            );
                                                        }}
                                                    >
                                                        <i className="pi pi-share-alt mr-2 text-orange-500"></i>
                                                        <span

                                                        >
                                                            F1A
                                                        </span>
                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>


                                            <li>
                                                <StyleClass
                                                    nodeRef={btnRefV1Statistics}
                                                    selector="#app-sidebar"
                                                    leaveToClassName="hidden"
                                                >
                                                    <nav
                                                        ref={btnRefV1Statistics}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                        style={{
                                                            borderRadius: "12px",
                                                        }}
                                                        onClick={() => {
                                                            navigate(
                                                                "v1statistics"
                                                            );
                                                        }}
                                                    >
                                                        <i className="pi pi-credit-card mr-2 text-orange-500"></i>
                                                        <span

                                                        >
                                                            V1
                                                        </span>
                                                        <Ripple/>
                                                    </nav>
                                                </StyleClass>
                                            </li>
                                        </ul>
                                    </li>


                                    {/* <li>
                                        <StyleClass
                                            nodeRef={btnRefSalesContract}
                                            selector="#app-sidebar"
                                            leaveToClassName="hidden"
                                        >
                                            <nav
                                                ref={btnRefSalesContract}
                                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                                style={{ borderRadius: "12px" }}
                                                onClick={() => {
                                                     navigate(
                                                         "salescontract"
                                                     );

                                                }}
                                            >
                                                <i className="pi pi-sitemap mr-2 text-pink-500"></i>
                                                <span

                                                >
                                                    Szerződés
                                                </span>

                                                <Ripple />
                                            </nav>
                                        </StyleClass>
                                    </li> */}

                                    <li>
                                        <nav
                                            onClick={() => {
                                                //clear();
                                                confirmLogout();
                                            }}
                                            className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                            style={{borderRadius: "12px"}}
                                        >
                                            <i className="pi pi-sign-out mr-2 text-bluegray-500"></i>
                                            <span className="font-medium">
                                                Kijelentkezés
                                            </span>
                                            <Ripple/>
                                        </nav>
                                    </li>
                                </ul>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="min-h-screen flex flex-column relative flex-auto">
                    <div
                        className="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static"
                        style={{height: "60px"}}
                    >
                        <StyleClass
                            nodeRef={btnRefSidebar}
                            selector="#app-sidebar"
                            enterClassName="hidden"
                            enterActiveClassName="fadeinleft"
                            leaveToClassName="hidden"
                            leaveActiveClassName="fadeoutleft"
                            hideOnOutsideClick
                        >
                            <nav
                                ref={btnRefSidebar}
                                className="p-ripple cursor-pointer block lg:hidden text-700 mr-3"
                            >
                                <i className="pi pi-bars text-4xl"></i>
                                <Ripple/>
                            </nav>
                        </StyleClass>
                        <StyleClass
                            nodeRef={btnRefTopbar}
                            selector="@next"
                            enterClassName="hidden"
                            enterActiveClassName="fadein"
                            leaveToClassName="hidden"
                            leaveActiveClassName="fadeout"
                            hideOnOutsideClick
                        >
                            <nav
                                ref={btnRefTopbar}
                                className="p-ripple cursor-pointer block lg:hidden text-700"
                            >
                                <i className="pi pi-ellipsis-v text-2xl"></i>
                                <Ripple />
                            </nav>
                        </StyleClass>
                        <ul className="list-none p-0 m-0 hidden lg:flex lg: align-items-center select-none lg:flex-row-reverse w-auto lg:w-full surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
                            <li>
                                <StyleClass
                                    nodeRef={btnRefProfil}
                                    selector="@grandparent"
                                    leaveToClassName="hidden"
                                    leaveActiveClassName="fadeout"
                                >
                                     <i className="pi pi-user text-base text-cyan-500 lg:text-2xl mr-2 lg:mr-0"></i>
                                      
                                    {/* <nav
                                        ref={btnRefProfil}
                                        className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-200 font-medium border-round cursor-pointer
                            transition-duration-150 transition-colors"
                                        // onClick={() => {
                                        //     clear();

                                        // }}
                                    >
                                        <i className="pi pi-user text-base text-cyan-500 lg:text-2xl mr-2 lg:mr-0"></i>
                                        <span

                                        >
                                            
                                        </span>
                                        <Ripple />
                                    </nav> */}
                                </StyleClass>
                            </li>

                            <li className="border-top-1 surface-border lg:border-top-none ml-auto">
                                <nav
                                    className="p-ripple flex px-3 py-2 align-items-center hover:surface-100 font-medium border-round
                            transition-duration-150 transition-colors"
                                >
                                    {/* TODO +company name */}
                                    <div className="text-900 font-medium">
                                        {userName
                                            ? userName
                                            : null}
                                    </div>
                                    <Ripple />
                                </nav>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-column flex-auto p-2 md:p-2 lg:p-3">
                        <Routes>
                            <Route path={"dashboard"} element={<Dashboard />} />
                            <Route path={"salescontract"} element={<SalesContract />} />
                            <Route path={"searchsalescontract"} element={<SearchSalesContract />} />
                            <Route path={"f1aletter"} element={<F1ALetterGeneration />} />
                            <Route path={"dm22letter"} element={<DM22LetterGeneration />} />
                            <Route path={"dm23aletter"} element={<DM23aLetterGeneration />} />
                            <Route path={"dm23bletter"} element={<DM23bLetterGeneration />} />
                            <Route path={"dm24letter"} element={<DM24LetterGeneration />} />
                            <Route path={"welcomeletter"} element={<WelcomeLetterGeneration />} />
                            <Route path={"dm22statistics"} element={<DM22Statistics />} />
                            <Route path={"dm23astatistics"} element={<DM23aStatistics />} />
                            <Route path={"dm23bstatistics"} element={<DM23bStatistics />} />
                            <Route path={"dm24statistics"} element={<DM24Statistics />} />
                            <Route path={"f1astatistics"} element={<F1AStatistics />} />
                            <Route path={"v1statistics"} element={<V1Statistics />} />
                            <Route path={"f5letter"} element={<F5LetterGeneration />} />
                            <Route path={"v1letter"} element={<V1LetterGeneration />} />
                            <Route path={"v2letter"} element={<V2LetterGeneration />} />
                            <Route path={"nyiletter"} element={<NyiLetterGeneration />} />
                            <Route path={"nyi2letter"} element={<Nyi2LetterGeneration />} />
                            <Route path={"optin"} element={<SearchOptIn />} />
                            <Route path={"salescontractcancellation"} element={<SalesContractCancellationList />} />
                            <Route path={"salescontractcancellationgeneration"} element={<SalesContractCancellationGeneration />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Main;


